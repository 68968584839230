define(['jquery', 'app', 'siteObj', 'module'], function($, app, siteObj) {
  
  window.imageZoomLoaded = true;
  // image gallery plugin for the product page

  var module = arguments[arguments.length - 1],
    bx_defaults = {
      'mode': module.config().bx_mode || 'vertical',
      'controls': module.config().bx_controls || false,
      'pause': module.config().bx_pause || 4000,
      'pager': module.config().bx_pager || false,
      'infiniteLoop': module.config().bx_infiniteloop || false,
      'displaySlideQty': module.config().bx_displaySlideQty || 4,
      'moveSlideQty': module.config().bx_moveSlideQty || 3,
      'hideControlOnEnd': module.config().bx_hideContolOnEnd || false,
      'productArea': module.config().productArea || '.product-area .media',
      'thumbnailSelectors': module.config().thumbnailSelector || '.product-area .media .product-thumbnails',
      'responsive': module.config().bx_responsive !== 'false',
      'startingSlide': module.config().startingSlide || 0
    },
    fb_defaults = {
      'autoHeight': true,
      'autoCenter': true,
      'padding': 0,
      'margin': 0
    };

  var $productImage = $('.product-content .product-image .product-img, .product-area .product-image .product-img, .img-zoom-instructions a, .main-product-image .item-promo-icon a');


  if ($(bx_defaults.thumbnailSelectors).children('li').length > 0) {
    //This is because the new MyP site layout is different so adding classes to target styles/hiding elements
    $('.product-content').addClass('multiImage');
  }
  // get main img alt text as we'll be changing it and want the original text
  var imgAlt = $('.product-image .product-img').attr('alt');
  var currentImage = 0;

  //part of the myproetin product image ab test todo remove when over
  if (siteObj.siteID === 83 && (document.querySelectorAll('.imageTypeB').length > 0 || document.querySelectorAll('.imageTypeC').length > 0)) {
    currentImage = 1;
  }



  $('.product-thumbnails li:first-child a').addClass('imgSelected');

  $('body').on('click', '.media .product-thumbnails a', function(e) {
    $('body').find('.main-product-image').removeClass('variationImage');
    // get index of link clicked on
    // var thumbPos = $(this).closest('li').index();
    var $thumbImg = $(this).find('img');
    var thumbPos = $thumbImg.attr('rel') ? $thumbImg.attr('rel') : $thumbImg.attr('data-image-index');
    // get url of new image from index
    var newImage = $(this).attr('href');
    // replace main image url and alt
    $(this).closest('.media').find('.product-image .product-img')
      .attr({
        src: newImage,
        alt: imgAlt + ': Image ' + (thumbPos + 1)
      })
      .removeAttr('width height');
    // add class to selected a to show it's been
    // selected after removing any that are already set
    $(this).closest('.product-thumbnails').find('a').removeClass('imgSelected');
    $(this).addClass('imgSelected');
    // set currenctImage value
    currentImage = thumbPos;

    e.preventDefault();
  });


  $productImage.click(function(e) {
    var initial_fb_content = $('.product-large-view-container').html();
    // Extra options for Fancybox to be merged with the defaults later on.
    var fb_opts = {
      showCloseButton: true,
      beforeShow: function() {
        // set the large image to be the one that was clicked on
        var $fbThumbs = $('.fancybox-wrap .product-thumbnails li a');

        if ($fbThumbs.length > 0) {
          if ($fbThumbs.find('img').attr('rel')) {
            $fbThumbs.find('img[rel="' + currentImage + '"]').parent().click();
          } else {
            $fbThumbs.find('img[data-image-index="' + currentImage + '"]').parent().click();
          }
        }

      },
      beforeClose: function() {
        // set the main product page image to be the one that was last clicked on
        var $mainThumbnails = $('.media  .product-thumbnails li');

        if ($mainThumbnails.length > 1) {
          if ($mainThumbnails.find('img').attr('rel')) {
            $mainThumbnails.find('a img[rel="' + currentImage + '"]').parent().click();
          } else {
            $mainThumbnails.find('img[data-image-index="' + currentImage + '"]').parent().click();
          }
        }
      },
      afterClose: function() {},
      //TODO: remove this once old basket A/B testing is over
      onComplete: function() {
        // set the large image to be the one that was clicked on
        var $fbThumbs = $('#fancybox-content .product-large-view .product-thumbnails li a');
        if ($fbThumbs.find('img').attr('rel')) {
          $fbThumbs.find('img[rel="' + currentImage + '"]').parent().click();
        } else {
          $fbThumbs.find('img[data-image-index="' + currentImage + '"]').parent().click();
        }
      },
      onCleanup: function() {
        // set the main product page image to be the one that was last clicked on
        var $mainThumbnails = $('.product-content .product-thumbnails li');
        if ($mainThumbnails.length > 1) {
          if ($mainThumbnails.find('img').attr('rel')) {
            $mainThumbnails.find('a img[rel="' + currentImage + '"]').parent().click();
          } else {
            $mainThumbnails.find('img[data-image-index="' + currentImage + '"]').parent().click();
          }
        }
      },
      onClosed: function() {}
    };

    // Initiate Fancybox to show the loading icon to the user asap.
    // Also, merges new settings with the default settings defined above.
    if ($().fancybox) {
      app.publish('tracking/record', 'image-zoom', 'toggle');
      $.fancybox(initial_fb_content, $.extend({}, fb_opts, fb_defaults));
    }
    e.preventDefault();
  });

  if ($(bx_defaults.thumbnailSelectors).children('li').length > bx_defaults.displaySlideQty) {


    if (bx_defaults.mode !== 'horizontal') {
      $(bx_defaults.productArea + ' .bx-wrapper')
        .before('<div class="bx-thumb-top"><a href="#" class="bx-prev"><i class="icon icon-arrow-up">' + siteObj.props.imageZoom.prevText + '</i></a></div>')
        .after('<div class="bx-thumb-bottom"><span class="bx-thumb-bottom-fadeout"></span><a href="#" class="bx-next"><i class="icon icon-arrow-down">' + siteObj.props.imageZoom.nextText + '</i></a></div>');
    } else {
      $(bx_defaults.productArea + ' .bx-wrapper')
        .before('<div class="bx-thumb-left"><a href="#" class="bx-prev"><i class="icon icon-arrow-left">' + siteObj.props.imageZoom.prevText + '</i></a></div>')
        .after('<div class="bx-thumb-right"><a href="#" class="bx-next"><i class="icon icon-arrow-right">' + siteObj.props.imageZoom.nextText + '</i></a></div>');
    }
  }
});
